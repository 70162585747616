body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
  color: #fff;
 
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.wrapper {
  background-image: url(../images/main-bcg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    @include media("<=1440px") {
      background-image: url(../images/big-bcg.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  
    @include media("<=1200px") {
      background-image: url(../images/mid-bcg.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
    }
  
    @include media("<=810px") {
      background-image: url(../images/tab-bcg.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  
    @include media("<=450px") {
      background-image: url(../images/mob-bcg.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}


.modal {
  
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(50px);
  background: rgba(0, 0, 0, 0.7);
  @include media("<=810px") {
    overflow: hidden;
  }
}

.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.shown {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
}

.modal-content {
  border-radius: 60px;
  box-shadow: 0px 20px 100px 0px rgba(243, 36, 36, 0.40);
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
  padding: 51px 20px 48px;
    border-radius: 60px;
  display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: linear-gradient(205deg, #57004A 15.67%, #48003C 84.33%);
  max-width: 488px;
  width: 100%;
  aspect-ratio: 488 / 458;
  

  @include media("<=1200px") {
    max-width: 400px;
  }

  @include media("<=810px") {
  }

  @include media("<=450px") {
    max-width: 280px;
    aspect-ratio: 280 / 357;
  }
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 44%;
      left: 49.5%;
      transform: translate(-50%, -50%);
      max-width: 514px;
      width: 109%;
      aspect-ratio: 514 / 543;
      background-image: url(../images/popup-bcg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include media("<=450px") {
        aspect-ratio: 294 / 409;
      }
    }
  
}

.popup__pic-before {
  position: absolute;
  top: 10%;
  z-index: -1;
  left: 18%;
  max-width: 430px;
  width: 100%;
  aspect-ratio: 430 / 328;
  transform: translate(-50%, 0px);
  animation: float 6s ease-in-out infinite;
  @include media("<=1440px") {
    left: 10%;
  }
    @include media("<=1200px") {
      left: 2%;
    }
  @include media("<=450px") {
    max-width: 300px;
    left: -25%;
    top: 6%;
  }
}

.popup__pic-after {
  position: absolute;
  bottom: 10%;
  right: 20%;
  max-width: 320px;
  width: 100%;
  aspect-ratio: 320 / 413;
  transform: translate(50%, 0);
  animation: float 8s ease-in-out infinite;
  @include media("<=1440px") {
    right: 18%;
  }
    @include media("<=1200px") {
      right: 8%;
    }
  @include media("<=450px") {
    max-width: 164px;
    right: -4%;
  }
}

/* Кнопка закриття */
#closeModal {}

.popup__text {
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 600;
  text-transform: uppercase;
  @include media("<=450px") {
    font-size: 14px;
  }
}

.popup__title {
  font-family: "Pacifico", cursive;
    position: relative;
    padding-bottom: 24px;
    width: 100%;
    font-size: 48px;
    font-weight: 900;
    line-height: 1.2;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D400E7 14.56%, #FF37D3 68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  @include media("<=1200px") {
    
  }

  @include media("<=450px") {
    font-size: 40px;
  }
    span {
      position: absolute;
      z-index: -1;
      top: 49%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      background: linear-gradient(180deg, #FFE500 0%, #FFFFFF 50%, #FFA800 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 6px transparent;
    }
}

.popup__messages {
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
  column-gap: 9px;
  width: 100%;
  @include media("<=1200px") {
    column-gap: 6px;
    margin-bottom: 20px;
    max-width: 332px;
    width: 100%;
  }

  @include media("<=450px") {
    max-width: 240px;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    display: block;
  }
}

.popup__win {
  max-width: 187px;
  width: 100%;
  aspect-ratio: 187 / 144;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    background-image: url(../images/popup-win-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  
  text-transform: uppercase;
  text-align: center;
  line-height: 1.1;
  @include media("<=1200px") {
    border-radius: 16px;
  }
    @include media("<=450px") {
      border-radius: 12px;
    }
}

.popup__win-title {
  font-size: 36px;
  font-weight: 800;
  padding-top: 16px;
  @include media("<=450px") {
    font-size: 24px;
  }
}

.popup__win-text {
  font-weight: 500;
  font-size: 15px;
  @include media("<=450px") {
      font-size: 12px;
    }
}

.popup__link {
  padding: 24px 20px 26px;
  border-radius: 80px;
  background: linear-gradient(180deg, #00E90C, #00B800, #00B809, #007000 );
    box-shadow: 0px 0px 21.1px 0px #420000 inset;
  max-width: 386px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  font-weight: 800;
  position: relative;
  z-index: 3;
  background-size: 100% 300%;
  transition: all 0.3s ease-in-out;

  @include media("<=1200px") {
    font-size: 20px;
    max-width: 332px;
    padding: 20px;
  }

  @include media("<=450px") {
    max-width: 240px;
    padding: 18px;
    font-size: 18px;
  }

  &:hover {
    color: #fff;
    background-position: 0 100%;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes floatdown {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(20px);
  }

  100% {
    transform: translatey(0px);
  }
}


.coin {
  position: absolute;
  animation: float 8s ease-in-out infinite;
  &-1 {
   top: 20%;
   left: 34%;
  @include media("<=1440px") {
    left: 30%;
  }
  }
  &-2 {
    bottom: 12%;
    right: 18%;
    animation-duration: 6s;
    @include media("<=1440px") {
      right: 14%;
    }
    @include media("<=1280px") {
      
    }
        @include media("<=810px") {
          
          right: 6%;
        }
        @include media("<=450px") {
         
          right: 2%;
          animation-duration: 9s;
          width: 14vw;
        }
  }
  
}

.object {
  position: absolute;
  animation: floatdown 8s ease-in-out infinite;
  &-1 {
    top: 30%;
      left: 25%;
      animation-duration: 9s;
    @include media("<=1440px") {
      left: 20%;
    }
      @include media("<=1200px") {
        left: 10%;
        top: 32%;
      }
            @include media("<=810px") {
              left: 0;
            }
            @include media("<=450px") {
              left: -4%;
              width: 20vw;
            }
  }
  &-2 {
    top: 24%;
     right: 20%;
      animation-duration: 6s;
    @include media("<=1440px") {
      right: 16%;
    }
    @include media("<=1200px") {
      right: 6%;
      top: 20%;
    }
        @include media("<=810px") {
          right: -6%;
        }
        @include media("<=450px") {
          top: 28%;
          width: 34vw;
        }
  }
}