.hero {
  height: 100%;
  position: relative;
  padding-top: 44px;
  width: 100%;
  flex: 0 1 100%;
  z-index: 3;
  @include media("<=1280px") {
    height: 73%;
    padding-top: 38px;
    padding-bottom: 30px;
  }
    @include media("<=810px") {
      padding-top: 12vw;
    }
    @include media("<=450px") {
      
     padding-bottom: 80px;
    }
    @include media("<=320px") {
      padding-top: 25px;
    }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 38px;
  row-gap: 31px;
  max-width: 474px;
  width: 100%;
  margin: 0 auto;
  @include media("<=1200px") {
    row-gap: 28px;
    column-gap: 26px;
  }
    @include media("<=450px") {
      max-width: 300px;
    }
}

.card {
  background-color: pink;
  background-image: url(../images/card-bcg.png);
  background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  max-width: 126px;
  width: 100%;
  aspect-ratio: 126 / 117;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 19px;
  transition: all 0.3s ease-in-out;
  @include media("<=1200px") {
    max-width: 113px;
  }
    @include media("<=450px") {
      max-width: 78px;
      flex: 0 1 30%;
      border-radius: 5px;
    }
  &::after {
    content: '';
    position: absolute;
    top: 44%;
    left: 49.5%;
    transform: translate(-50%, -50%);
    max-width: 138px;
    width: 109%;
    aspect-ratio: 1 / 1;
    background-image: url(../images/card-frame.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  &::before {
    content: '';
      position: absolute;
      width: 20px;
        height: 12%;
        transform: rotate(45deg) translateX(-350%);
    background: #FFF;
    border-radius: 30px;
      filter: blur(15px);
      animation: shine 6s linear 1s;
      animation-iteration-count: infinite;
      @include media("<=1200px") {
        width: 16px;
      }
            @include media("<=450px") {
              width: 11px;
            }
  }
  &:hover {
    filter: drop-shadow(0 0 30px #FF47DE) drop-shadow(0 0 10px #FF47DE);
  }
}

  .message {
    position: absolute;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    border-radius: 19px;
      background: radial-gradient(50% 50% at 50% 50%, #FF47DE 0%, #9D0782 100%);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50) inset;
    &:nth-of-type(2) {
      border-radius: 19px;
        background: radial-gradient(50% 50% at 50% 50%, #BF99B9 0%, #8A5781 100%);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50) inset;
        @include media("<=450px") {
            border-radius: 5px;
          }
    }
        @include media("<=450px") {
          border-radius: 5px;
        }
  }

.message__text {
  width: 100%;
    font-family: "Pacifico", cursive;
    position: relative;
    font-size: 44px;
    font-weight: 900;
    line-height: 1.1;
    background: linear-gradient(0deg, #D400E7 14.56%, #FF37D3 68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include media("<=1200px") {
      font-size: 40px;
    }
        @include media("<=450px") {
          font-size: 24px;
        }
    span {
        position: absolute;
        z-index: -1;
        top: 49%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background: linear-gradient(180deg, #FFE500 0%, #FFFFFF 50%, #FFA800 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 6px transparent;
      }
}  

.message__small {
  display: block;
  font-size: 16px;
  font-weight: 600;
  @include media("<=450px") {
    font-size: 12px;
  }
}

.nowin {
  font-size: 16px;
  font-weight: 800;
  @include media("<=450px") {
      font-size: 12px;
    }
}

.win {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 26px;
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 15px;
}

.win__logo {
  max-width: 258px;
  position: relative;
  z-index: 2;
  margin-bottom: -10px;
  @include media("<=1200px") {
    max-width: 171px;
    margin-bottom: -8px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.win-message {
  text-align: center;
  font-size: 16px;
  transition: opacity 0.5s ease;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 12px;
    background: rgba(102, 14, 97, 0.80);
    backdrop-filter: blur(2px);
    width: 100%;
    max-width: 474px;
    padding: 12px 10px 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    @include media("<=1200px") {
      font-size: 14px;
      max-width: 384px;
    }
    &::before, &::after {
      content: "";
      width: 15px;
      aspect-ratio: 1 / 1;
      background-image: url(../images/win-pic.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: block;
    }
}





.win__text {
  font-size: 16px;
  font-weight: 500;
  @include media("<=1200px") {
      font-size: 14px;
    }
  
    @include media("<=450px") {
      font-size: 10px;
    }
}

.win__title {
  background: linear-gradient(145.21deg, rgb(227, 157, 40), rgb(231, 200, 52));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
      font-size: 28px;
      font-weight: 800;
      @include media("<=1200px") {
          font-size: 22px;
        }
      
        @include media("<=450px") {
          font-size: 18px;
        }
}

@keyframes shine {
  0% {
    height: 15%;
    transform: rotate(45deg) translateX(-350%);
  }

  4% {
    height: 160%;
      transform: rotate(45deg) translateX(0%);
      opacity: 1;
  }

  8% {
    height: 15%;
      transform: rotate(45deg) translateX(350%);
      opacity: 0;
  }
    24% {
      height: 15%;
        transform: rotate(45deg) translateX(350%);
        opacity: 0;
    }
  
    32% {
      height: 15%;
        transform: rotate(45deg) translateX(350%);
        opacity: 0;
    }
  
    40% {
      
    }
        48% {
          
        }
        56% {
          
        }
    62% {
      
    }
         70% {
          
         }
    
         78% {
          
         }
    
         86% {
          
         }
    
         92% {
          
         }
        100% {
          opacity: 0;
        }
}



