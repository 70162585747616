.footer {
  width: 100%;
  padding: 0 0 40px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  @include media("<=430px") {
   
  }
  
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}

.footer__logo {
  position: relative;
  flex: 0 1 100%;
  max-width: 200px;
  width: 100%;  
  @include media("<=900px") {
    max-width: 150px;
  }
  @include media("<=450px") {
    max-width: 125px;
  }

  img {
    width: 100%;
  } 
}

.copyright {
  font-size: 14px;
  font-weight: 500;
  @include media("<=430px") {
    font-size: 10px;
  }
}

